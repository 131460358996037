import React, { useState } from "react";
import user_01 from "../common/assets/images/user_01.png";
import user_02 from "../common/assets/images/user_02.png";
import user_03 from "../common/assets/images/user_03.png";
import user_04 from "../common/assets/images/user_04.png";
import user_05 from "../common/assets/images/user_05.png";
import user_06 from "../common/assets/images/user_06.png";
import backgroundImage from "../common/assets/images/testomonial-bg.svg";

type Testimonial = {
  image: string;
  name: string;
  position: string;
  feedback: string;
};

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < testimonialData.length - 2) {
      setCurrentIndex(currentIndex + 2);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 2);
    }
  };

  const testimonialData: Testimonial[] = [
    {
      image: user_01,
      name: "John Doe",
      position: "Marketing Executive",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: user_02,
      name: "Laura Smith",
      position: "Senior Manager",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: user_03,
      name: "Michael Brown",
      position: "Software Engineer",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: user_04,
      name: "Emily Davis",
      position: "HR Specialist",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: user_05,
      name: "Chris Johnson",
      position: "Product Manager",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
    {
      image: user_06,
      name: "Anna Martinez",
      position: "Creative Director",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    },
  ];

  return (
    <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-auto my-48">
      {/* Left side container */}
      <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-fit flex-col">
        <h4 className="text-xl text-secondary-dark text-left font-semibold mb-5">
          SUCCESS STORIES
        </h4>
        <h4 className="text-4xl text-primary-dark text-left font-semibold mb-4">
          Recruiters Recommend
        </h4>
        <p className="text-left text-primary-light">
          ABC Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum
        </p>
      </div>
      {/* Right side container */}
      <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-auto flex justify-start items-center md:items-start flex-col relative">
        <img
          src={backgroundImage}
          alt="Scout Share"
          className="mt-16 md:mt-32 lg:mt-0"
        />
        <div className="absolute flex flex-row justify-center items-center -mt-10">
          <button
            className="w-10 h-10 rounded-full bg-white cursor-pointer text-black"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          {testimonialData
            .slice(currentIndex, currentIndex + 2)
            .map((item, index) => (
              <div
                key={index}
                className="shadow-custom rounded-xl px-8 py-16 flex flex-col justify-center items-center bg-white w-1/3 mx-5 h-1/2"
              >
                <div className="relative p-4">
                  <div className="absolute inset-0 border-4 border-dashed border-blue-500 rounded-full"></div>
                  <img
                    src={item.image}
                    alt={item.name}
                    className="relative w-32 h-32 rounded-full bg-white p-2 shadow-lg"
                  />
                </div>
                <h4 className="text-2xl text-primary-dark text-center font-semibold mt-5">
                  {item.name}
                </h4>
                <p className="text-center text-primary-light">
                  {item.position}
                </p>
                <p className="text-center text-primary-light mt-5 text-sm">
                  {item.feedback}
                </p>
              </div>
            ))}
          <button
            className="w-10 h-10 rounded-full bg-white cursor-pointer text-black"
            onClick={handleNext}
            disabled={currentIndex >= testimonialData.length - 2}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
