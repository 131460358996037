export enum UserRolesSelectOptions {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  USER = "USER",
}

export const UserRolesValues = Object.values(
  UserRolesSelectOptions
) as string[];

export enum UserRoles {
  SUPER_ADMIN = "SUPER_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  USER = "USER",
}
