import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import InputField from "../../components/input-field.component";
import ProfileCard from "../../components/profile-card.component";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { getAllResumes } from "../../services/resumes-api.service";
import userSearchIco from "../../common/assets/images/user_search.png";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate, useSearchParams } from "react-router-dom";
import useToken from "../../hooks/useToken";
import FooterDashboard from "../../components/footer-dashboard.component";
import LoadingSpinner from "../../components/loading-spinner.component";

export default function SearchResults() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resumes, setResumes] = useState<any[] | null>([]);

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);

  // Filter states

  // Keywords filtering states
  const [keywords, setKeywords] = useState(searchParams.get("keywords") || "");
  const [excludeKeywords, setExcludeKeywords] = useState(
    searchParams.get("excludeKeywords") || ""
  );
  const [mandatoryKeywords, setMandatoryKeywords] = useState(
    searchParams.get("mandatoryKeywords") || ""
  );
  // Locations filtering states
  const [locations, setLocations] = useState(
    searchParams.get("locations") || ""
  );
  // Experience filtering states
  const [minExperience, setMinExperience] = useState(
    searchParams.get("minExperience") || ""
  );
  const [maxExperience, setMaxExperience] = useState(
    searchParams.get("maxExperience") || ""
  );
  // Salary filtering states
  const [minSalary, setMinSalary] = useState(
    searchParams.get("minSalary") || ""
  );
  const [maxSalary, setMaxSalary] = useState(
    searchParams.get("maxSalary") || ""
  );
  // Company filtering states
  const [currentCompany, setCurrentCompany] = useState(
    searchParams.get("currentCompanyNames") || ""
  );
  const [previousCompany, setPreviousCompany] = useState(
    searchParams.get("previousCompanyNames") || ""
  );
  const [companyNames, setCompanyNames] = useState(
    searchParams.get("companyNames") || ""
  );
  const [companySearchOption, setCompanySearchOption] = useState(
    "currentCompanyNames"
  );
  const [companyInputValue, setCompanyInputValue] = useState("");
  // Designation filtering states
  const [currentDesignation, setCurrentDesignation] = useState(
    searchParams.get("currentDesignations") || ""
  );
  const [previousDesignation, setPreviousDesignation] = useState(
    searchParams.get("previousDesignations") || ""
  );
  const [designationNames, setDesignationNames] = useState(
    searchParams.get("designations") || ""
  );
  const [designationSearchOption, setDesignationSearchOption] = useState(
    "currentDesignations"
  );
  const [designationInputValue, setDesignationInputValue] = useState("");

  // Display toggle states
  const [isShowKeywords, setIsShowKeywords] = useState(false);
  const [isShowExcludeKeywords, setIsShowExcludeKeywords] = useState(false);
  const [isShowLocations, setIsShowLocations] = useState(false);
  const [isShowExperience, setIsShowExperience] = useState(false);
  const [isShowCurrentCompany, setIsShowCurrentCompany] = useState(false);
  const [isShowCurrentDesignation, setIsShowCurrentDesignation] =
    useState(false);

  // New state for filters
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  // Update companySearchOption based on presence of Company in URL parameters
  useEffect(() => {
    if (currentCompany) {
      setCompanySearchOption("currentCompanyNames");
      setCompanyInputValue(currentCompany);
    }
    if (previousCompany) {
      setCompanySearchOption("previousCompanyNames");
      setCompanyInputValue(previousCompany);
    }
    if (companyNames) {
      setCompanySearchOption("companyNames");
      setCompanyInputValue(companyNames);
    }
  }, [currentCompany, previousCompany, companyNames]);

  // Handle input value changes in company
  const handleCompanyInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value; // Extract value from the event
    setCompanyInputValue(value);
    if (companySearchOption === "currentCompanyNames") {
      setCurrentCompany(value);
    } else if (companySearchOption === "previousCompanyNames") {
      setPreviousCompany(value);
    } else if (companySearchOption === "companyNames") {
      setCompanyNames(value);
    }
  };

  // Update companySearchOption based on presence of Designation in URL parameters
  useEffect(() => {
    if (currentDesignation) {
      setDesignationSearchOption("currentDesignations");
      setDesignationInputValue(currentDesignation);
    }
    if (previousDesignation) {
      setDesignationSearchOption("previousDesignations");
      setDesignationInputValue(previousDesignation);
    }
    if (designationNames) {
      setDesignationSearchOption("designations");
      setDesignationInputValue(designationNames);
    }
  }, [currentDesignation, previousDesignation, designationNames]);

  // Handle input value changes in designation
  const handleDesignationInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value; // Extract value from the event
    setDesignationInputValue(value);
    if (designationSearchOption === "currentDesignations") {
      setCurrentDesignation(value);
    } else if (designationSearchOption === "previousDesignations") {
      setPreviousDesignation(value);
    } else if (designationSearchOption === "designations") {
      setDesignationNames(value);
    }
  };

  // Update URL params based on filter states
  useEffect(() => {
    const params: any = {
      keywords,
      excludeKeywords,
      locations,
      minExperience,
      maxExperience,
      minSalary,
      maxSalary,
      currentCompany,
      previousCompany,
      companyNames,
      currentDesignation,
      previousDesignation,
      designationNames,
      page: currentPage.toString(),
      pageSize: pageSize.toString(),
    };

    // Remove any undefined or empty parameters
    Object.keys(params).forEach(
      (key) => !params[key] && params[key] !== undefined && delete params[key]
    );

    setSearchParams(params);
  }, [
    keywords,
    excludeKeywords,
    locations,
    minExperience,
    maxExperience,
    minSalary,
    maxSalary,
    currentCompany,
    previousCompany,
    companyNames,
    currentDesignation,
    previousDesignation,
    designationNames,
    currentPage,
    pageSize,
  ]);

  // Page size options
  const pageSizeOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];

  const applyFilters = () => {
    setIsFiltersApplied(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (isFiltersApplied) {
      const accessToken = localStorage.getItem("accessToken");
      const storedUser = JSON.parse(localStorage.getItem("user") || "");
      if (accessToken && (user || storedUser)) {
        handleGetAllResumes(accessToken);
        setIsFiltersApplied(false); // Reset the filter flag after applying
      }
    }
  }, [isFiltersApplied, currentPage, pageSize]);

  useEffect(() => {
    if (!isFiltersApplied) {
      const accessToken = localStorage.getItem("accessToken");
      const storedUser = JSON.parse(localStorage.getItem("user") || "");
      if (accessToken && (user || storedUser)) {
        handleGetAllResumes(accessToken);
      }
    }
  }, [currentPage, pageSize]);

  const handleGetAllResumes = async (token: string) => {
    try {
      setIsLoading(true);

      const searchParams: any = {};
      if (keywords) searchParams.normalKeywords = keywords;
      if (excludeKeywords) searchParams.excludeKeywords = excludeKeywords;
      if (locations) searchParams.locations = locations;
      if (minExperience) searchParams.minExperience = minExperience;
      if (maxExperience) searchParams.maxExperience = maxExperience;
      if (currentCompany) searchParams.currentCompanyNames = currentCompany;
      if (previousCompany) searchParams.previousCompanyNames = previousCompany;
      if (companyNames) searchParams.companyNames = companyNames;
      if (currentDesignation)
        searchParams.currentDesignations = currentDesignation;
      if (previousDesignation)
        searchParams.previousDesignations = previousDesignation;
      if (designationNames) searchParams.designations = designationNames;

      const response = await getAllResumes(
        token,
        currentPage,
        pageSize,
        searchParams
      );

      if (response.success) {
        setResumes(response?.data?.data?.resumes);
        setTotal(response?.data?.data?.total);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const onPageChange = (event: any) => {
    setCurrentPage(event.page + 1); // Page is zero-indexed
    setPageSize(event.rows); // Ensure pageSize is updated properly
    setIsFiltersApplied(true); // Apply filters when page changes
  };

  const clearFilters = () => {
    setKeywords("");
    setExcludeKeywords("");
    setLocations("");
    setMinExperience("");
    setMaxExperience("");
    setCurrentCompany("");
    setPreviousCompany("");
    setCompanyNames("");
    setCompanyInputValue("");
    setCurrentDesignation("");
    setPreviousDesignation("");
    setDesignationNames("");
    setDesignationInputValue("");
    setIsFiltersApplied(true); // Trigger re-fetch with cleared filters
    setCurrentPage(1); // Reset to first page
    setSearchParams({}); // Clear all URL params
  };

  return (
    <React.Fragment>
      <div>
        <div className="h-32 bg-white shadow-xl w-full flex flex-row items-center justify-between lg:px-20">
          <div className="flex items-center justify-start">
            <img src={userSearchIco} className="w-7 h-7 mr-5" />
            <h3 className="font-bold text-lg">Advanced Search</h3>
            <i className="fa-solid fa-chevron-right ml-5"></i>
            <h3 className="font-normal text-lg ml-5">
              <span className="font-bold">
                {new Intl.NumberFormat("en-US").format(total)}
              </span>{" "}
              profiles found
            </h3>
          </div>
          <button className="w-fit h-14 bg-white border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-md font-bold py-2 px-4 rounded-2xl transition duration-300">
            Save Search
          </button>
        </div>
        <div className="lg:px-24 lg:pt-24 min-h-screen w-full flex">
          <div className="w-1/4 bg-slate-100 rounded-xl h-min-screen mx-4 px-8 py-12 mb-20">
            <h1 className="text-4xl text-slate-700 font-bold mb-12">Filters</h1>
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              {/* Keywords Filter */}
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowKeywords(!isShowKeywords)}
              >
                <h1 className="text-xl font-bold text-slate-700">Keywords</h1>
                <i
                  className={`fa-solid ${
                    isShowKeywords ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowKeywords && (
                <>
                  <InputField
                    icon="fa-solid fa-magnifying-glass"
                    type="text"
                    placeholder="Search keywords"
                    value={keywords}
                    onChange={(e: any) => setKeywords(e.target.value)}
                  />
                  <p
                    className="text-sm underline text-blue-800 cursor-pointer font-semibold mt-5"
                    onClick={() =>
                      setIsShowExcludeKeywords(!isShowExcludeKeywords)
                    }
                  >
                    Exclude Keywords
                  </p>
                  {isShowExcludeKeywords && (
                    <InputField
                      icon="fa-solid fa-magnifying-glass"
                      type="text"
                      placeholder="Exclude keywords"
                      value={excludeKeywords}
                      onChange={(e: any) => setExcludeKeywords(e.target.value)}
                    />
                  )}
                </>
              )}
            </div>
            {/* Locations Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowLocations(!isShowLocations)}
              >
                <h1 className="text-xl font-bold text-slate-700">Locations</h1>
                <i
                  className={`fa-solid ${
                    isShowLocations ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowLocations && (
                <>
                  <InputField
                    icon="fa-solid fa-location-dot"
                    type="text"
                    placeholder="Search locations"
                    value={locations}
                    onChange={(e: any) => setLocations(e.target.value)}
                  />
                </>
              )}
            </div>

            {/* Experience Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowExperience(!isShowExperience)}
              >
                <h1 className="text-xl font-bold text-slate-700">Experience</h1>
                <i
                  className={`fa-solid ${
                    isShowExperience ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowExperience && (
                <>
                  <InputField
                    icon="fa-solid fa-calendar-day"
                    type="number"
                    placeholder="Min Experience (years)"
                    value={minExperience}
                    onChange={(e: any) => setMinExperience(e.target.value)}
                  />
                  <InputField
                    icon="fa-solid fa-calendar-day"
                    type="number"
                    placeholder="Max Experience (years)"
                    value={maxExperience}
                    onChange={(e: any) => setMaxExperience(e.target.value)}
                  />
                </>
              )}
            </div>

            {/* Current Company Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsShowCurrentCompany(!isShowCurrentCompany)}
              >
                <h1 className="text-xl font-bold text-slate-700">Company</h1>
                <i
                  className={`fa-solid ${
                    isShowCurrentCompany ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowCurrentCompany && (
                <>
                  <div className="input-field">
                    <i className="fa-solid fa-building text-slate-400"></i>
                    <input
                      className="w-full ml-5 border-none outline-none bg-white"
                      type="text"
                      placeholder="Search company"
                      value={companyInputValue}
                      onChange={handleCompanyInputChange}
                    />
                  </div>
                  <p>
                    Search in :
                    <select
                      id="companySearchOption"
                      name="companySearchOption"
                      className="ml-2"
                      value={companySearchOption}
                      onChange={(e) => setCompanySearchOption(e.target.value)}
                    >
                      <option value="currentCompanyNames">
                        Current Company
                      </option>
                      <option value="previousCompanyNames">
                        Previous Company
                      </option>
                      <option value="companyNames">
                        Current and Previous Company
                      </option>
                    </select>
                  </p>
                </>
              )}
            </div>

            {/* Current Designation Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() =>
                  setIsShowCurrentDesignation(!isShowCurrentDesignation)
                }
              >
                <h1 className="text-xl font-bold text-slate-700">
                  Designation
                </h1>
                <i
                  className={`fa-solid ${
                    isShowCurrentDesignation
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                  }`}
                ></i>
              </div>
              {isShowCurrentDesignation && (
                <>
                  <div className="input-field">
                    <i className="fa-solid fa-briefcase text-slate-400"></i>
                    <input
                      className="w-full ml-5 border-none outline-none bg-white"
                      type="text"
                      placeholder="Search designation"
                      value={designationInputValue}
                      onChange={handleDesignationInputChange}
                    />
                  </div>
                  <p>
                    Search in :
                    <select
                      id="designationSearchOption"
                      name="designationSearchOption"
                      className="ml-2"
                      value={designationSearchOption}
                      onChange={(e) =>
                        setDesignationSearchOption(e.target.value)
                      }
                    >
                      <option value="currentDesignations">
                        Current Designation
                      </option>
                      <option value="previousDesignations">
                        Previous Designation
                      </option>
                      <option value="designations">
                        Current and Previous Designation
                      </option>
                    </select>
                  </p>
                </>
              )}
            </div>
            <div className="w-full flex flex-col">
              <button
                className="w-full h-14 bg-blue-800 hover:bg-blue-700 text-white py-2 px-4 rounded-2xl my-2"
                onClick={applyFilters}
              >
                Apply Filters
              </button>
              <button
                className="w-full h-14 bg-gray-400 text-white py-2 px-4 rounded-2xl my-2"
                onClick={clearFilters}
              >
                Clear All Filters
              </button>
            </div>
          </div>
          <div className="w-3/4 h-min-screen">
            <div className="w-full flex flex-row justify-between items-center">
              <div className="flex flex-row justify-start items-center w-1/2">
                {resumes && resumes.length ? (
                  <Paginator
                    first={(currentPage - 1) * pageSize}
                    rows={pageSize}
                    totalRecords={total}
                    onPageChange={onPageChange}
                  />
                ) : null}
              </div>
              <div className="flex flex-row justify-end items-center w-1/2">
                {resumes && resumes.length ? (
                  <>
                    <p className="mr-2">Show:</p>
                    <Dropdown
                      value={pageSize}
                      options={pageSizeOptions}
                      onChange={(e) => {
                        setPageSize(e.value);
                        setCurrentPage(1); // Reset to first page on page size change
                        setIsFiltersApplied(true); // Apply filters when page size changes
                      }}
                      placeholder="Select Page Size"
                      className="custom-dropdown w-20 ml-2"
                    />
                  </>
                ) : null}
              </div>
            </div>

            <div className="flex flex-wrap justify-center lg:justify-start px-20">
              {isLoading ? (
                <div className="flex justify-center w-full h-full">
                  <LoadingSpinner />
                </div>
              ) : resumes && resumes.length ? (
                resumes.map((resume: any) => (
                  <ProfileCard
                    candidateName={resume.candidateName}
                    currentEmployment={resume.currentEmployment}
                    previousExperience={resume.previousExperience}
                    education={resume.education}
                    location={resume.location}
                    keySkills={resume.keySkills}
                    profileSummary={resume.profileSummary}
                    phoneNumber={resume.phoneNumber}
                    totalExperience={resume.totalExperience}
                    key={resume.id} // Add a key prop for the map
                  />
                ))
              ) : (
                <div className="flex flex-col justify-center items-center w-full mt-32">
                  <h1 className="text-slate-300 font-bold text-2xl">
                    Sorry, we couldn't find any resumes matching your search.
                  </h1>
                  <p className="text-slate-300 text-lg mt-8">
                    Please try adjusting your filters or search criteria and
                    search again.
                  </p>
                </div>
              )}
            </div>
            <div className="flex justify-center mt-5 mb-32">
              {resumes && resumes.length ? (
                <Paginator
                  first={(currentPage - 1) * pageSize}
                  rows={pageSize}
                  totalRecords={total}
                  onPageChange={onPageChange}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <FooterDashboard />
      <ToastContainer />
    </React.Fragment>
  );
}
