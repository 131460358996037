import React from "react";
import brand_01 from "../common/assets/images/brand_01.png";
import brand_02 from "../common/assets/images/brand_02.png";
import brand_03 from "../common/assets/images/brand_03.png";
import brand_04 from "../common/assets/images/brand_04.png";
import brand_05 from "../common/assets/images/brand_05.png";
import brand_06 from "../common/assets/images/brand_06.png";

export default function BrandsBanner() {
  const brandLogos = [
    brand_01,
    brand_02,
    brand_03,
    brand_04,
    brand_05,
    brand_06,
  ];

  return (
    <div className="w-full bg-secondary-light py-10 mt-32">
      <div className="container mx-auto flex justify-center items-center">
        <div className="flex overflow-x-auto space-x-10">
          {brandLogos.map((logo, index) => (
            <div key={index} className="flex-shrink-0">
              <img src={logo} alt={`Brand ${index + 1}`} className="h-20" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
