import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardTopNavComponent from "../../components/dashboard/dashboard-topnav.component";
import FooterDashboard from "../../components/footer-dashboard.component";

export default function UserDashboardLayout() {
  return (
    <React.Fragment>
      <div className="flex">
        <DashboardTopNavComponent />
        <div className="py-20 mt-14 w-full">
          <div className="h-screen bg-white">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
