import React, { useState } from "react";
import defaultPlaceholder from "../common/assets/images/user-placeholder.png";

interface ProfileCardProps {
  candidateName: string;
  currentEmployment: {
    companyName?: string;
    designation?: string;
  };
  previousExperience: {
    companyName?: string;
    designation?: string;
  }[];
  education: {
    degree?: string;
    institution?: string;
    year?: number;
  }[];
  location: string;
  keySkills: string[];
  profileSummary: string;
  phoneNumber: string; // Optional phone number if already available
  totalExperience: number;
}

export default function ProfileCard({
  candidateName,
  currentEmployment,
  previousExperience,
  education,
  location,
  keySkills,
  profileSummary,
  phoneNumber,
  totalExperience,
}: ProfileCardProps) {
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);

  const handleViewPhoneNumber = async () => {
    setShowPhoneNumber(true);
  };

  return (
    <div className="bg-slate-50 rounded-lg w-full h-min-96 flex flex-row items-center justify-center shadow-md border-2 border-slate-200 my-12">
      <div className="flex flex-col p-12 border-r-2 border-r-slate-200 w-2/3">
        <h1 className="text-slate-800 font-semibold text-xl flex-1">
          {candidateName}
          <i className="fa-solid fa-pen ml-5 text-secondary-dark cursor-pointer"></i>
        </h1>
        <div className="flex-1 text-slate-400 mt-4">
          <span className="text-xs">
            <i className="fa-solid fa-briefcase" /> {`${totalExperience} y`}
          </span>
          <span className="text-xs ml-8">
            <i className="fa-solid fa-wallet" /> 5.7 Lacs
          </span>
          <span className="text-xs ml-8">
            <i className="fa-solid fa-location-dot" /> {location}
          </span>
        </div>
        <div className="overflow-x-auto mt-4">
          <table className="table-auto w-full text-sm">
            <tbody>
              <tr className="h-8">
                <td className="font-semibold w-32">Current</td>
                <td>
                  {currentEmployment?.designation} at{" "}
                  {currentEmployment?.companyName}
                </td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Previous</td>
                <td>
                  {previousExperience[0]?.designation} at{" "}
                  {previousExperience[0]?.companyName}
                </td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Education</td>
                <td>
                  {education[0]?.degree} {education[0]?.institution} (
                  {education[0]?.year})
                </td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Pref. location</td>
                <td>{location}</td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Key Skills</td>
                <td>{keySkills.join(" | ")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-1/3 flex flex-col justify-center items-center p-10">
        <img
          src={defaultPlaceholder}
          className="w-32 h-32 rounded-full shadow-lg"
        />
        <p className="font-semibold text-xs text-center mt-5">
          {profileSummary}
        </p>
        <button
          onClick={handleViewPhoneNumber}
          className="w-full h-10 border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-md font-semibold py-2 px-4 rounded-lg transition duration-300 mt-5 mb-2"
        >
          {showPhoneNumber ? phoneNumber : "View phone number"}
        </button>
        <a href={`tel:${phoneNumber}`} className="w-full">
          <button className="w-full h-10 border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-md font-semibold py-2 px-4 rounded-lg transition duration-300 my-2">
            Call candidate
          </button>
        </a>
      </div>
    </div>
  );
}
