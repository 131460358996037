export enum VIEWS {
  // PUBLIC ROUTES
  HOME = "/",
  HOME_WITH_PREFIX = "/home",
  ACTIVATE_ACCOUNT = "/activate-account",
  RESET_PASSWORD = "/reset-password",

  // USER ROUTES
  USER_DASHBOARD_LAYOUT = "/user",
  USER_DASHBOARD = "/user/dashboard",
  USER_SEARCH = "/user/search",
  USER_UPLOAD = "/user/upload",
  USER_SETTINGS = "/user/settings",
  USER_RESULTS = "/user/results",

  // ADMIN ROUTES
  ADMIN_DASHBOARD_LAYOUT = "/admin",
  ADMIN_DASHBOARD = "/admin/dashboard",
  ADMIN_COMPANIES = "/admin/companies",
  ADMIN_USERS = "/admin/users",
  ADMIN_SETTINGS = "/admin/settings",
}
