import axios from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Create user API call
export const createUser = async (token: any, formData: FormData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending only the JSON data use this format
    },
    timeout: 5000,
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.USER.ROOT}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Get all users API call
export const getAllUsers = async (
  token: any,
  currentPage: number,
  pageSize: number,
  searchQuery: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.USER.ROOT_WITH_PAGINATOR(
        currentPage,
        pageSize,
        searchQuery
      )}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};

// Get single user API call
export const getSingleUser = async (token: string, id: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Update user API call
interface UpdateUserPayload {
  firstName?: string;
  lastName?: string;
  company?: string;
}
export const updateUser = async (
  token: any,
  id: string,
  firstName: string,
  lastName: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // Build the payload based on provided values
  const payload: UpdateUserPayload = {};
  if (firstName.trim() !== "") {
    payload.firstName = firstName;
  }
  if (lastName.trim() !== "") {
    payload.lastName = lastName;
  }

  try {
    const response = await axios.put(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      payload,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Update user profile image API call
export const updateUserProfileImage = async (
  token: any,
  id: string,
  formData: FormData
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // If you sending the files use this format
    },
  };
  try {
    const response = await axios.put(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};

// Delete user API call
export const deleteUser = async (token: any, id: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.delete(
      `${ENDPOINT.USER.ROOT_WITH_ID(id)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return { success: false, error: error.response.data.message };
  }
};
