import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Get all Companies by lesson API call
export const getAllCompaniesTypeList = async (token: any) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.TYPES.COMPANY_TYPE_LIST}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};
