import React, { useState } from "react";
import FormTextField from "../form-text.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../common/providers/user.provider";
import useToken from "../../hooks/useToken";
import { toast, ToastContainer } from "react-toastify";
import Button from "../button.component";
import { uploadResumeWithText } from "../../services/resumes-api.service";
import LoadingSpinner from "../loading-spinner.component";

export default function UploadText() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // =====================================================================================================================
  // CREATE ITEM FUNCTIONALITY
  // =====================================================================================================================

  const [formData, setFormData] = useState({
    resumeText: "",
  });

  //Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle create form action
  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const { resumeText } = formData;

    if (resumeText === "") {
      toast.error("Please enter resume text");
    } else {
      try {
        setIsLoading(true);

        // Create FormData object to handle file upload
        const formData = new FormData();

        formData.append("resumeText", resumeText);

        // Calling to the API service
        const response = await uploadResumeWithText(token, formData);

        // Handling the response
        if (response.success) {
          setFormData({
            resumeText: "",
          });
          window.scrollTo(0, 0);
          toast.success(response?.data?.message);
        } else {
          toast.error(response.error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit mx-auto bg-slate-100 rounded-xl px-16 pt-12">
        <h1 className="text-slate-400">
          Paste here your resume text{" "}
          <i className="fa-solid fa-arrow-down ml-2"></i>
        </h1>
        <form className="w-full bg-slate-100 p-5 rounded-xl my-10">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mb-3">
              <FormTextField
                label="Resume Text *"
                name="resumeText"
                value={formData.resumeText}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
                rows={8}
              />
            </div>
            <div className="flex flex-row justify-end items-center w-full">
              <div className="w-1/5 px-3 mb-6">
                <Button
                  name={"Submit Resume Text"}
                  handleAction={handleFormAction}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
