import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import Button from "../button.component";
import useToken from "../../hooks/useToken";
import { useAuth } from "../../common/providers/user.provider";
import {
  uploadResumeWithPdf,
  updateResume,
} from "../../services/resumes-api.service";
import LoadingSpinner from "../loading-spinner.component";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export default function UploadPdf() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ file: null as File | null });
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [rejected, setRejected] = useState<File[]>([]);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);
  const [resumeData, setResumeData] = useState({
    id: "",
    candidateName: "",
    email: "",
    phoneNumber: "",
    location: "",
    file: null,
    file_format: "",
  });
  const [isDisplayFileUploader, setIsDisplayFileUploader] = useState(true);
  const [isDisplayUpdateForm, setIsDisplayUpdateForm] = useState(false);

  const FILE_LIMIT = 30 * 1024 * 1024; // 30 MB in bytes

  interface FileWithPreview extends File {
    preview: string;
  }

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      if (dropzoneDisabled) {
        return;
      }

      if (acceptedFiles.length) {
        const totalFiles = files.length + acceptedFiles.length;
        if (totalFiles > 20) {
          toast.error("Cannot upload more than 20 files.");
          return;
        }

        const validFiles = acceptedFiles.filter(
          (file) => file.size <= FILE_LIMIT
        );
        const invalidFiles = acceptedFiles.filter(
          (file) => file.size > FILE_LIMIT
        );

        if (invalidFiles.length) {
          toast.error(
            `Some files exceed the limit. Maximum allowed file size is ${
              FILE_LIMIT / (1024 * 1024)
            } MB.`
          );
        }

        setFiles((prevFiles) => [
          ...prevFiles,
          ...validFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          ),
        ]);

        setFormData({ ...formData, file: validFiles[0] || null });

        if (fileRejections.length) {
          setRejected((previousFiles) => [
            ...previousFiles,
            ...fileRejections.map((rejection) => rejection.file),
          ]);
        }
      }
    },
    [dropzoneDisabled, formData, files, setRejected]
  );

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    setDropzoneDisabled(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 20,
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleStartProcessingTheResumes = async () => {
    // Convert the files array to a JSON string and save to local storage
    const filesJSON = JSON.stringify(files);
    localStorage.setItem("resumeFiles", filesJSON);

    // Process the first file
    if (files.length) {
      await handleFormAction(files);
    }
  };

  const handleFormAction = async (updatedFiles: any) => {
    if (!updatedFiles.length) {
      toast.error("Please select material files");
      return;
    }

    try {
      setIsLoading(true);
      setIsDisplayFileUploader(false);

      console.log("CHECK FILES UPLOADING.... : ", updatedFiles[0]);

      // Create FormData object to handle file upload
      const formDataToSend = new FormData();
      formDataToSend.append("file", updatedFiles[0]);

      // Calling the API service
      const response = await uploadResumeWithPdf(token, formDataToSend);

      // Handling the response
      if (response.success) {
        setIsDisplayUpdateForm(true);

        // Setting up the total files
        const totalFilesToBeUploaded = localStorage.getItem(
          "totalFilesToBeUploaded"
        );
        if (!totalFilesToBeUploaded) {
          localStorage.setItem(
            "totalFilesToBeUploaded",
            files.length.toString()
          );
        }

        setResumeData({
          id: response?.data?.data?._id,
          candidateName: response?.data?.data?.candidateName,
          email: response?.data?.data?.email,
          phoneNumber: response?.data?.data?.phoneNumber,
          location: response?.data?.data?.location,
          file: response?.data?.data?.file,
          file_format: response?.data?.data?.file_format,
        });
        toast.success(response?.data?.message);
        setDropzoneDisabled(true);
      } else {
        toast.error(response.error);
        setDropzoneDisabled(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  // Add this useEffect to log updated resumeData
  useEffect(() => {
    console.log("**Updated resumeData: ", resumeData);
    console.log(
      "URL : ",
      `${process.env.REACT_APP_S3_BUCKET_URL}/${resumeData?.file}`
    );
  }, [resumeData]);

  const handleUpdateAndNext = async () => {
    try {
      setIsLoading(true);

      // Update resume data with the edited information
      const response = await updateResume(token, resumeData?.id, resumeData);

      if (response.success) {
        toast.success(response?.data?.message);

        // Update files state and handle the next file upload
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.slice(1);

          // If there are more files, process the next one
          if (updatedFiles.length > 0) {
            handleFormAction(updatedFiles);
          } else {
            setIsDisplayFileUploader(true);
            setIsDisplayUpdateForm(false);
            toast.success("All files have been processed.");
          }

          return updatedFiles;
        });
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during update action:", error);
      setIsLoading(false);
    }
  };

  console.log("DRAGGED & DROPPED FILES : ", files);

  return (
    <React.Fragment>
      {isDisplayFileUploader ? (
        <div className="w-full h-fit mx-auto bg-slate-100 rounded-xl px-16 pt-12">
          <form className="w-full bg-slate-100 p-5 rounded-xl my-10">
            <div>
              <div {...getRootProps({ className: "custom-class" })}>
                <input {...getInputProps()} accept="application/pdf" />
                <div className="flex flex-col items-center justify-center gap-4">
                  {isDragActive ? (
                    <div className="flex flex-col items-center justify-center py-5 h-64 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-blue-600 border-dashed">
                      <i className="fa-solid fa-cloud-arrow-up text-blue-700 text-6xl mt-5" />
                      <p className="text-slate-400 text-sm mt-1">
                        Drop the files here ...
                      </p>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center py-5 h-64 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-gray-300 border-dashed">
                      <i className="fa-solid fa-cloud-arrow-up text-slate-200 text-6xl mt-5" />
                      <p className="text-slate-400 text-sm mt-1">
                        Drag and drop or click to upload files
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <section className="mt-2 mb-10">
                {files.map((file, index) => (
                  <div
                    key={index.toString()}
                    className="w-full px-5 py-3 rounded-lg shadow-xm bg-slate-50 text-slate-700 flex flex-row items-center justify-between my-2"
                  >
                    <div className="w-full flex flex-row items-center justify-start">
                      {file?.type === "application/pdf" ? (
                        <i className="fa-regular fa-file-pdf text-2xl text-blue-700 mx-3" />
                      ) : (
                        <i className="fa-regular fa-file-word text-2xl text-blue-700 mx-3" />
                      )}
                      <p className="w-full truncate text-slate-500">
                        {file.name}
                      </p>
                    </div>
                    <div className="w-full flex flex-row items-center justify-end">
                      <button
                        type="button"
                        onClick={() => removeFile(file.name)}
                      >
                        <i className="fa-solid fa-xmark text-xl text-slate-400" />
                      </button>
                    </div>
                  </div>
                ))}
              </section>
            </div>
            <div className="flex flex-row justify-end items-center w-full">
              <div className="w-1/5 px-3 mb-6">
                <Button
                  name={"Start Processing"}
                  handleAction={handleStartProcessingTheResumes}
                />
              </div>
            </div>
          </form>
        </div>
      ) : null}
      {isDisplayUpdateForm ? (
        <div className="bg-slate-50 rounded-xl w-full shadow-md min-h-screen mt-16 p-8 grid grid-cols-2 gap-4">
          <div className="p-5 bg-slate-200 h-full rounded-lg flex justify-center items-center">
            {resumeData?.file_format === ".pdf" ? (
              <iframe
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/${resumeData?.file}`}
                width="100%"
                height="100%"
                title="Resume Preview"
              ></iframe>
            ) : resumeData?.file_format === ".docx" ||
              resumeData?.file_format === ".doc" ? (
              <DocViewer
                documents={[
                  {
                    uri: `${process.env.REACT_APP_S3_BUCKET_URL}/${resumeData?.file}`,
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <div className="text-slate-400 font-normal text-lg flex flex-col items-center justify-center">
                <i className="fa-regular fa-face-meh-blank text-6xl mb-2"></i>
                <p>Preview not available</p>
              </div>
            )}
          </div>
          <div className="p-5 h-full">
            <div className="flex flex-row items-center justify-between bg-white rounded-xl shadow-sm p-4 mb-6">
              <p className="text-slate-700 text-lg">
                Processing{" "}
                {parseInt(
                  localStorage.getItem("totalFilesToBeUploaded") || "0"
                ) - files.length + 1}
                /{localStorage.getItem("totalFilesToBeUploaded") || "0"}
              </p>
              <i className="fa-regular fa-clock text-xl text-slate-700" />
            </div>
            <div className="bg-white w-full p-6 rounded-xl shadow-sm mb-6">
              <p className="text-slate-700 text-sm font-medium mb-4">
                Please update the details
              </p>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Name
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.candidateName}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      candidateName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.email}
                  onChange={(e) =>
                    setResumeData({ ...resumeData, email: e.target.value })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.phoneNumber}
                  onChange={(e) =>
                    setResumeData({
                      ...resumeData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-slate-700 text-sm font-medium mb-1">
                  Location
                </label>
                <input
                  type="text"
                  className="w-full p-2 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                  value={resumeData.location}
                  onChange={(e) =>
                    setResumeData({ ...resumeData, location: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-row justify-end">
                <Button
                  name={"Update and Next"}
                  handleAction={handleUpdateAndNext}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
    </React.Fragment>
  );
}
