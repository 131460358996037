import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import Button from "../../components/button.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import FooterDashboard from "../../components/footer-dashboard.component";
import InputField from "../../components/input-field.component";

export default function Search() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Keywords filtering states
  const [keywords, setKeywords] = useState("");
  const [excludeKeywords, setExcludeKeywords] = useState("");
  const [isShowExcludeKeywords, setIsShowExcludeKeywords] = useState(false);
  const [mandatoryKeywords, setMandatoryKeywords] = useState(false);
  // Locations filtering states
  const [locations, setLocations] = useState("");
  // Experience filtering states
  const [minExperience, setMinExperience] = useState("");
  const [maxExperience, setMaxExperience] = useState("");
  // Salary filtering states
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  // Company filtering states
  const [company, setCompany] = useState("");
  const [companySearchOption, setCompanySearchOption] = useState(
    "currentCompanyNames"
  );
  // Designation filtering states
  const [designation, setDesignation] = useState("");
  const [designationSearchOption, setDesignationSearchOption] = useState(
    "currentDesignations"
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    if (accessToken && (user || storedUser)) {
      // Handle token and user state
    }
  }, [user]);

  const handleSearch = () => {
    const params: any = {};
    if (keywords) {
      if (mandatoryKeywords) {
        params.mandatoryKeywords = keywords;
      } else {
        params.normalKeywords = keywords;
      }
    }
    if (excludeKeywords) {
      params.excludeKeywords = excludeKeywords;
    }
    if (locations) params.locations = locations;
    if (minExperience) params.minExperience = minExperience;
    if (maxExperience) params.maxExperience = maxExperience;
    if (minSalary) params.minSalary = minSalary;
    if (maxSalary) params.maxSalary = maxSalary;
    if (company) {
      if (companySearchOption === "currentCompanyNames") {
        params.currentCompanyNames = company;
      } else if (companySearchOption === "previousCompanyNames") {
        params.previousCompanyNames = company;
      } else if (companySearchOption === "companyNames") {
        params.companyNames = company;
      }
    }
    if (designation) {
      if (designationSearchOption === "currentDesignations") {
        params.currentDesignations = designation;
      } else if (designationSearchOption === "previousDesignations") {
        params.previousDesignations = designation;
      } else if (designationSearchOption === "designations") {
        params.designations = designation;
      }
    }

    const searchParams = new URLSearchParams(params).toString();
    navigate(`/user/results?${searchParams}`);
  };

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-16 min-h-screen w-full flex">
        <div className="w-3/4 bg-slate-100 rounded-xl h-min-screen mx-4 p-12 shadow-xl mb-32">
          <h1 className="text-2xl text-slate-700 font-bold">
            Search Candidates
          </h1>
          <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
            {/* Keywords Filter */}
            <div className="flex justify-between items-center cursor-pointer">
              <h1 className="text-lg font-bold text-slate-700">Keywords</h1>
            </div>
            <>
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Search keywords"
                value={keywords}
                onChange={(e: any) => setKeywords(e.target.value)}
              />
              <div className="w-full flex flex-row justify-between items-center">
                <p>
                  <input
                    type="checkbox"
                    id="mandatoryKeywords"
                    name="mandatoryKeywords"
                    className="cursor-pointer mr-2"
                    checked={mandatoryKeywords}
                    onChange={(e) => setMandatoryKeywords(e.target.checked)}
                  />
                  Mark all keywords as mandatory
                </p>
                <p>
                  Search keyword in
                  <select
                    id="searchOption"
                    name="searchOption"
                    className="ml-2"
                  >
                    <option value="resumeTitle">Resume Title</option>
                    <option value="resumeTitleAndKeywords">
                      Resume Title and Keywords
                    </option>
                    <option value="resumeSynopsis">Resume Synopsis</option>
                    <option value="entireResume">Entire Resume</option>
                  </select>
                </p>
              </div>
              <p
                className="text-sm underline text-blue-800 cursor-pointer font-semibold mt-5 w-fit"
                onClick={() => setIsShowExcludeKeywords(!isShowExcludeKeywords)}
              >
                + Add Exclude Keywords
              </p>
              {isShowExcludeKeywords && (
                <InputField
                  icon="fa-solid fa-magnifying-glass"
                  type="text"
                  placeholder="Search keywords"
                  value={excludeKeywords}
                  onChange={(e: any) => setExcludeKeywords(e.target.value)}
                />
              )}
            </>
          </div>

          {/* Experience Filter */}
          <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
            <div className="flex justify-between items-center cursor-pointer mb-5">
              <h1 className="text-lg font-bold text-slate-700">Experience</h1>
            </div>
            <div className="flex items-center space-x-2 w-1/2">
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Min Experience"
                value={minExperience}
                onChange={(e: any) => setMinExperience(e.target.value)}
              />
              <span>to</span>
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Max Experience"
                value={maxExperience}
                onChange={(e: any) => setMaxExperience(e.target.value)}
              />
              <span>Year</span>
            </div>
          </div>

          {/* Locations Filter */}
          <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
            <div className="flex justify-between items-center cursor-pointer">
              <h1 className="text-lg font-bold text-slate-700">
                Location of Candidate
              </h1>
            </div>
            <>
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Add location"
                value={locations}
                onChange={(e: any) => setLocations(e.target.value)}
              />
            </>
          </div>

          {/* Salary Filter */}
          <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
            <div className="flex justify-between items-center cursor-pointer mb-5">
              <h1 className="text-lg font-bold text-slate-700">Salary</h1>
            </div>
            <div className="flex items-center space-x-2 w-1/2">
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Min Salary"
                value={minSalary}
                onChange={(e: any) => setMinSalary(e.target.value)}
              />
              <span>to</span>
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Max Salary"
                value={maxSalary}
                onChange={(e: any) => setMaxSalary(e.target.value)}
              />
            </div>
          </div>

          {/* Company Filter */}
          <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
            <div className="flex justify-between items-center cursor-pointer">
              <h1 className="text-lg font-bold text-slate-700">Company</h1>
            </div>
            <InputField
              icon="fa-solid fa-magnifying-glass"
              type="text"
              placeholder="Search company"
              value={company}
              onChange={(e: any) => setCompany(e.target.value)}
            />
            <p>
              Search company in
              <select
                id="companySearchOption"
                name="companySearchOption"
                className="ml-2"
                value={companySearchOption}
                onChange={(e) => setCompanySearchOption(e.target.value)}
              >
                <option value="currentCompanyNames">Current Company</option>
                <option value="previousCompanyNames">Previous Company</option>
                <option value="companyNames">
                  Current and Previous Company
                </option>
              </select>
            </p>
          </div>

          {/* Designation Filter */}
          <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
            <div className="flex justify-between items-center cursor-pointer">
              <h1 className="text-lg font-bold text-slate-700">Designation</h1>
            </div>
            <InputField
              icon="fa-solid fa-magnifying-glass"
              type="text"
              placeholder="Search designation"
              value={designation}
              onChange={(e: any) => setDesignation(e.target.value)}
            />
            <p>
              Search designation in
              <select
                id="designationSearchOption"
                name="designationSearchOption"
                className="ml-2"
                value={designationSearchOption}
                onChange={(e) => setDesignationSearchOption(e.target.value)}
              >
                <option value="currentDesignations">Current Designation</option>
                <option value="previousDesignations">
                  Previous Designation
                </option>
                <option value="designations">
                  Current and Previous Designation
                </option>
              </select>
            </p>
          </div>

          {/* Actions */}
          <div className="flex flex-row space-x-6 justify-start items-center mt-12">
            <Button name="Search" handleAction={handleSearch} />
            <Button
              name="Reset Filters"
              handleAction={() => {
                setKeywords("");
                setExcludeKeywords("");
                setLocations("");
                setMinExperience("");
                setMaxExperience("");
                setMinSalary("");
                setMaxSalary("");
                setCompany("");
                setCompanySearchOption("currentCompany");
                setDesignation("");
                setDesignationSearchOption("currentDesignation");
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
    </React.Fragment>
  );
}
