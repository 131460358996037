import React, { useEffect, useState } from "react";
import { useAuth } from "../../common/providers/user.provider";
import LoadingSpinner from "../../components/loading-spinner.component";
import { toast, ToastContainer } from "react-toastify";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import { UserRoles } from "../../common/enums/user-roles.enum";
import { truncateText } from "../../common/utils/truncate-paragraghs";
import { Paginator } from "primereact/paginator";
import FormPopupComponent from "../../components/form-popup.component";
import FormInputField from "../../components/form-input.component";
import FormTextField from "../../components/form-text.component";
import Button from "../../components/button.component";
import {
  createUser,
  deleteUser,
  getAllUsers,
} from "../../services/user-api.service";
import defaultPlaceholder from "../../common/assets/images/default-user-avatar.png";
import { formatTimestamp } from "../../common/utils/date-format";
import SelectField from "../../components/select-field.component";
import { EquipmentTypeValues } from "../../common/enums/equipment-types.enum";
import { getAllCompaniesTypeList } from "../../services/types-api.service";
import Select from "react-select";
import PopupConfirmationComponent from "../../components/popup-confirmation.component";

interface CompanyOption {
  value: string;
  label: string;
}

export default function AdminUsers() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<any | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [showNoContentMessage, setShowNoContentMessage] =
    useState<boolean>(false);
  const [companiesTypeList, setCompaniesTypeList] = useState<CompanyOption[]>(
    []
  );

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'

  //PrimeReact paginator handler
  const onPageChange = (event: any) => {
    const newPage = event.page + 1;
    const newPageSize = event.rows;
    setPageSize(newPageSize);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
    if (accessToken && (user || storedUser)) {
      handleGetAllUsers(accessToken);
      handleGetAllCompaniesTypeList(accessToken);
    }
    const timeoutId = setTimeout(
      () => {
        setShowNoContentMessage(true);
      },
      allUsers?.length === 0 ? 0 : 100
    );
    return () => clearTimeout(timeoutId);
  }, [currentPage, pageSize]);

  // =====================================================================================================================
  // GET ALL ITEMS FUNCTIONALITY
  // =====================================================================================================================

  const handleGetAllUsers = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllUsers(
        token,
        currentPage,
        pageSize,
        searchQuery
      );
      // Handling the response
      if (response.success) {
        setAllUsers(response?.data?.data?.items);
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleGetAllCompaniesTypeList = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllCompaniesTypeList(token);
      // Handling the response
      if (response.success) {
        const formattedCompanies = response.data.data.map((company: any) => ({
          value: company._id,
          label: company.name,
        }));
        setCompaniesTypeList(formattedCompanies);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  // =====================================================================================================================
  // SEARCH FUNCTIONALITY
  // =====================================================================================================================
  useEffect(() => {
    // Getting the token from the local storage
    const accessToken = localStorage.getItem("accessToken");

    // Stopping trigger this functionality when the page mount
    if (searchQuery.trim() === "") {
      handleGetAllUsers(accessToken);
    } else {
      const debounceSearch = setTimeout(() => {
        handleSearchCompanies(accessToken);
      }, 750);
      return () => clearTimeout(debounceSearch);
    }
  }, [searchQuery]); // Only run this effect when searchQuery changes

  const handleSearchCompanies = async (accessToken: any) => {
    try {
      setIsLoading(true);
      const response = await getAllUsers(
        accessToken,
        currentPage,
        pageSize,
        searchQuery
      );
      if (response.success) {
        setAllUsers(response?.data?.data?.items);
        setTotal(response?.data?.data?.pageInfo?.totalDocuments);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleSearchKeyUp = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // =====================================================================================================================
  // CREATE ITEM FUNCTIONALITY
  // =====================================================================================================================
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
  });

  // Clearing the form data when opening the create form
  const handleCreateItem = async () => {
    setIsAddFormOpen(!isAddFormOpen);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      company: "",
    });
    setIsAddFormOpen(true);
  };

  // Handling the select options
  const handleSelectChange = (selectedOption: any) => {
    setFormData({
      ...formData,
      company: selectedOption ? selectedOption.value : "",
    });
  };

  //Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle create form action
  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const { firstName, lastName, email, company } = formData;

    if (firstName === "") {
      toast.error("Please enter first name");
    } else if (lastName === "") {
      toast.error("Please enter last name");
    } else if (email === "") {
      toast.error("Please enter email");
    } else if (company === "") {
      toast.error("Please enter company");
    } else {
      try {
        setIsLoading(true);

        // Create FormData object to handle file upload
        const formData = new FormData();

        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("company", company);

        // Calling to the API service
        const response = await createUser(token, formData);

        // Handling the response
        if (response.success) {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            company: "",
          });
          setIsAddFormOpen(false);
          handleGetAllUsers(token);
          window.scrollTo(0, 0);
          toast.success(response?.data?.message);
        } else {
          toast.error(response.error);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  // =====================================================================================================================
  // VIEW SINGLE ITEM FUNCTIONALITY
  // =====================================================================================================================
  const [isItemViewOpen, setIsItemViewOpen] = useState<boolean>(false);

  const handleViewItem = async (item: any) => {
    setAllUsers(item);
    setIsItemViewOpen(true);
  };

  // =====================================================================================================================
  // DELETE FUNCTIONALITY
  // =====================================================================================================================
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);

  const handleConfirmDeleteItem = async (itemId: string) => {
    setUserId(itemId);
    setConfirmationOpen(true);
  };

  const handleDelete = async () => {
    try {
      setConfirmationOpen(false);
      setIsLoading(true);
      // Calling to the API service
      const response = await deleteUser(token, userId);
      // Handling the response
      if (response.success) {
        handleGetAllUsers(token);
        toast.success(response?.data?.message);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  console.log("companiesTypeList : ", companiesTypeList);

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-12 min-h-screen">
        <h1 className="text-5xl text-slate-700 font-bold">Users</h1>
        <div className="w-full flex justify-end items-center">
          {user?.role === UserRoles.SUPER_ADMIN ? (
            <button
              className="bg-blue-700 hover:bg-blue-800 text-white font-normal py-2 px-4 rounded-xl cursor-pointer text-sm"
              onClick={() => handleCreateItem()}
            >
              Add User <i className="fa-solid fa-plus ml-2"></i>
            </button>
          ) : null}
        </div>
        <div className="w-full px-3 bg-slate-50 rounded-lg mt-10">
          <div
            className={` w-full h-14 mb-4 py-4 px-5 border-none rounded-lg  font-normal flex flex-row items-center justify-center`}
          >
            <i className={`fa-solid fa-magnifying-glass text-slate-400`}></i>
            <input
              className={`w-full ml-5 border-none outline-none bg-slate-50 text-slate-500`}
              type={"text"}
              name={"search_query"}
              placeholder={"Search Users"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
              onKeyUp={(e) => handleSearchKeyUp(e)}
            />
          </div>
        </div>
        {allUsers?.length > 0 ? (
          <div className="relative overflow-x-auto shadow-xl sm:rounded-lg mt-16">
            <table className="w-full text-xs lg:text-sm text-left">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Role
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Account Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created At
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {allUsers?.map((item: any) => (
                  <tr
                    className="bg-white border-b hover:bg-gray-50"
                    key={item._id}
                  >
                    <td className="px-6 py-4">
                      <div className="flex flex-row justify-start items-center">
                        <img
                          src={
                            item.profileImage !== null
                              ? `${process.env.REACT_APP_S3_BUCKET_URL}/${item.profileImage}`
                              : defaultPlaceholder
                          }
                          alt={`${item.firstName} ${item.lastName}`}
                          className="rounded-full w-10 h-10 border-2 border-blue-300"
                        />
                        <div className="flex flex-col justify-center items-start ml-2">
                          <p className="font-bold text-slate-500">
                            {item?.firstName} {item?.lastName}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 max-w-sm">{item?.email}</td>
                    <td className="px-6 py-4 max-w-sm">
                      {item?.company?.name ? item?.company?.name : "-"}
                    </td>
                    <td className="px-6 py-4">{item?.role}</td>
                    <td className="px-6 py-4">
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-normal ${
                          item?.isAccountActivate
                            ? "bg-green-100 text-green-500"
                            : "bg-orange-100 text-orange-500"
                        }`}
                      >
                        {item?.isAccountActivate ? "Activate" : "Pending"}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      {formatTimestamp(item?.createdAt)}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex justify-start items-center">
                        {user?.role === UserRoles.SUPER_ADMIN &&
                        item?.role !== UserRoles.SUPER_ADMIN ? (
                          <button
                            className="rounded-xl bg-rose-400 hover:bg-rose-500 p-2 text-white font-bold w-10 h-10 mx-1"
                            onClick={() => handleConfirmDeleteItem(item?._id)}
                          >
                            <i className="fa-regular fa-trash-can"></i>
                          </button>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center items-center w-full overflow-x-auto">
              <Paginator
                first={(currentPage - 1) * pageSize}
                rows={pageSize}
                totalRecords={total || 0}
                onPageChange={onPageChange}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                prevPageLinkIcon={<p>Previous</p>}
                nextPageLinkIcon={<p>Next</p>}
                firstPageLinkIcon={null}
                lastPageLinkIcon={null}
              />
            </div>
          </div>
        ) : showNoContentMessage ? (
          <div className="w-full flex flex-col justify-center items-center mt-24">
            <span className="rounded-full h-64 w-64 bg-slate-50 flex flex-col justify-center items-center text-slate-300">
              <i className="fa-solid fa-circle-info text-8xl text-slate-200"></i>
              <h1 className="text-slate-300 mt-5 text-xs">
                No Companies available
              </h1>
            </span>
          </div>
        ) : null}
      </div>

      {/* Create form goes here */}
      <FormPopupComponent
        popUpDisplay={isAddFormOpen}
        handleClose={() => setIsAddFormOpen(false)}
        handleOpen={() => setIsAddFormOpen(true)}
        popUpTitle={"Add User"}
      >
        <form className="w-full bg-slate-100 p-5 rounded-xl my-10">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2 px-3 mb-3">
              <FormInputField
                icon="fa-solid fa-user"
                type="text"
                label="First name *"
                placeholder="Enter first name"
                name="firstName"
                value={formData.firstName}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-3">
              <FormInputField
                icon="fa-solid fa-user"
                type="text"
                label="Last name *"
                placeholder="Enter last name"
                name="lastName"
                value={formData.lastName}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
              />
            </div>
            <div className="w-full px-3 mb-3">
              <FormInputField
                icon="fa-solid fa-envelope"
                type="email"
                label="Email *"
                placeholder="Enter email address"
                name="email"
                value={formData.email}
                onChange={(data: { name: string; value: any }) =>
                  handleInputChange(data.name, data.value)
                }
              />
            </div>
            <div className="w-full px-3 mb-16">
              <label className="text-xs text-slate-400 ml-1">
                {"Select the Company *"}
              </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isSearchable={true}
                name="company"
                options={companiesTypeList}
                onChange={handleSelectChange}
              />
            </div>
            <div className="w-full px-3 mb-6">
              <Button name={"Submit"} handleAction={handleFormAction} />
            </div>
          </div>
        </form>
      </FormPopupComponent>

      {/* Delete pop up goes here */}
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Warning"}
        popUpContent={"Are you sure want to delete this user?"}
        handleConfirm={() => handleDelete()}
      />

      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
