import React from "react";
import glow from "../common/assets/images/header_marketing_banner_glow.png";
import speaker from "../common/assets/images/header_marketing_banner_speaker.png";

export default function HeaderMarketingBanner() {
  return (
    <div className="flex justify-between items-center bg-black w-full z-50 h-20">
      <img src={glow} alt="" className="w-64 h-full" />
      <div className="flex flex-row justify-center items-center">
        <h1 className="text-md text-white font-normal flex flex-row justify-center items-center">
          <img src={speaker} alt="" className="w-12 h-auto mr-5" />
          Now us AI-powered Resdex Enterprise to take your hiring to the next
          level!
        </h1>
        <button className="bg-white text-black rounded-md cursor-pointer text-md py-2 px-6 ml-5">
          Know more
        </button>
      </div>
      <img src={glow} alt="" className="w-64 h-full" />
    </div>
  );
}
