import { Navigate, Outlet } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import { decodeJwt } from "../common/utils/decode-jwt";
import { UserRoles } from "../common/enums/user-roles.enum";

const AdminProtectedRoutes = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { role } = decodeJwt(accessToken);

  return accessToken && role === UserRoles.SUPER_ADMIN ? (
    <Outlet />
  ) : (
    <Navigate to={VIEWS.HOME} />
  );
};

export default AdminProtectedRoutes;