import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Upload resume with PDF API call
export const uploadResumeWithPdf = async (token: any, formData: FormData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // If you sending the files use this format
    },
    timeout: 500000,
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.RESUME.UPLOAD_WITH_PDF}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Upload resume with text API call
export const uploadResumeWithText = async (token: any, formData: FormData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending only the JSON data use this format
    },
    timeout: 500000,
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.RESUME.UPLOAD_WITH_TEXT}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Upload resume with CSV API call
export const uploadResumeWithCsv = async (token: any, formData: FormData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // If you sending the files use this format
    },
    timeout: 500000,
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.RESUME.UPLOAD_WITH_CSV}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Updated uploaded resume data API call
export const updateResume = async (
  token: any,
  resumeId: string,
  resumeData: any
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending the files use this format
    },
    timeout: 5000,
  };
  try {
    const response = await axios.put(
      `${ENDPOINT.RESUME.ROOT_WITH_ID(resumeId)}`,
      resumeData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Get all Resumes API call
export const getAllResumes = async (
  token: string,
  currentPage: number,
  pageSize: number,
  searchParams: Record<string, any>
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.RESUME.ROOT_WITH_PAGINATOR(
        currentPage,
        pageSize,
        searchParams
      )}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};
