import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import FormInputField from "../../components/form-input.component";
import Button from "../../components/button.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import SelectField from "../../components/select-field.component";
import FormTextField from "../../components/form-text.component";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VIEWS } from "../../routes/routes";
import { UserRoles } from "../../common/enums/user-roles.enum";
import useToken from "../../hooks/useToken";
import FooterDashboard from "../../components/footer-dashboard.component";
import { UploadTypeOptions } from "../../common/enums/upload-type.enum";
import UploadPdf from "../../components/dashboard/upload-pdf.component";
import UploadCsv from "../../components/dashboard/upload-csv.component";
import UploadText from "../../components/dashboard/upload-text.component";

export default function Upload() {
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const lessonId: string = searchParams.get("id")?.toString() || "";
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [lesson, setLesson] = useState<any | null>(null);
  const [materials, setMaterials] = useState<any | null>(null);
  const [videoUrls, setVideoUrls] = useState<any>([]);
  const [showNoContentMessage, setShowNoContentMessage] =
    useState<boolean>(false);
  const [lessonMaterialId, setLessonMaterialId] = useState<string>("");
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [selectedUploadType, setSelectedUploadType] = useState(
    UploadTypeOptions.PDF
  );

  // Handling the pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);

  //PrimeReact paginator handler
  const onPageChange = (event: any) => {
    const newPage = event.page + 1;
    const newPageSize = event.rows;
    setPageSize(newPageSize);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    console.log("VIDEO URLS LIST : ", videoUrls?.length);
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");

    const timeoutId = setTimeout(
      () => {
        setShowNoContentMessage(true);
      },
      materials?.length === 0 ? 0 : 100
    );
    return () => clearTimeout(timeoutId);
  }, [currentPage, pageSize]);

  // =====================================================================================================================
  // CREATE ITEM FUNCTIONALITY
  // =====================================================================================================================
  const [formData, setFormData] = useState({
    files: [] as File[],
    title: "",
  });

  // Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Clearing the form data when opening the create form
  const handleCreateItem = async () => {
    setIsAddFormOpen(!isAddFormOpen);
    setFormData({
      files: [],
      title: "",
    });
    setIsAddFormOpen(true);
  };

  // FILE UPLOADING FUNCTIONALITIES GOES HERE ===============================================================
  interface FileWithPreview extends File {
    preview: string;
  }

  // To select multiple files
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [rejected, setRejected] = useState<File[]>([]);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);

  // Define constants for file size limits
  const FILE_LIMIT = 30 * 1024 * 1024; // 30 MB in bytes

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Ensure multiple files are accepted
      const newFiles = acceptedFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      }));

      const validFiles = newFiles.filter((file) => file.size <= FILE_LIMIT);
      const invalidFiles = newFiles.filter((file) => file.size > FILE_LIMIT);

      if (invalidFiles.length) {
        toast.error(
          `Some files exceed the limit. Maximum allowed file size is ${
            FILE_LIMIT / (1024 * 1024)
          } MB.`
        );
      }

      setFiles((previousFiles) => [...previousFiles, ...validFiles]);
      setRejected((previousFiles) => [
        ...previousFiles,
        ...invalidFiles.map((file) => file),
      ]);

      // Update state with the files
      setFormData({
        ...formData,
        files: [...formData.files, ...validFiles],
      });
    },
    [formData, setRejected]
  );

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    setFormData((formData) => ({
      ...formData,
      files: formData.files.filter((file) => file.name !== name),
    }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 20,
    onDrop,
  });

  useEffect(() => {
    console.log("FILES : ", files);
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const { files, title } = formData;
    if (!files.length) {
      toast.error("Please select material files");
    } else if (title === "") {
      toast.error("Please enter title");
    } else {
      try {
        setIsLoading(true);

        // Create FormData object to handle file upload
        const formDataToSend = new FormData();

        files.forEach((file) => formDataToSend.append("files", file));
        formDataToSend.append("title", title);

        // // Calling to the API service
        // const response = await createLessonMaterial(token, lessonId, formDataToSend);

        // // Handling the response
        // if (response.success) {
        //   setFormData({
        //     files: [],
        //     title: "",
        //   });
        //   setFiles([]);
        //   window.scrollTo(0, 0);
        //   handleGetAllMaterialsByLesson(token);
        //   toast.success(response?.data?.message);
        //   setIsAddFormOpen(false);
        // } else {
        //   toast.error(response.error);
        // }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  console.log("files : ", files);

  return (
    <React.Fragment>
      <div className="lg:px-24 lg:pt-16 min-h-screen">
        <div className="flex flex-row justify-between items-center w-full mb-12">
          <h1 className="text-5xl text-slate-700 font-bold">Upload Resume/s</h1>
          <div className="w-fit">
            <select
              id="searchOption"
              name="searchOption"
              className="ml-2"
              value={selectedUploadType}
              onChange={(event: any) =>
                setSelectedUploadType(event.target.value)
              }
            >
              <option value={UploadTypeOptions.PDF}>
                PDF/DOCX Based Multiple Resumes Upload
              </option>
              <option value={UploadTypeOptions.CSV}>
                CSV Based Bulk Resume Data Upload
              </option>
              <option value={UploadTypeOptions.TEXT}>
                Text Based Resume Upload
              </option>
            </select>
          </div>
        </div>
        {selectedUploadType === UploadTypeOptions.PDF ? (
          <UploadPdf />
        ) : selectedUploadType === UploadTypeOptions.CSV ? (
          <UploadCsv />
        ) : selectedUploadType === UploadTypeOptions.TEXT ? (
          <UploadText />
        ) : null}
      </div>
      <div className="h-32"></div>
      <FooterDashboard />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
