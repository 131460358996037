import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import logo from "../common/assets/images/logo.png";

export default function TopNavbarComponent(props: {
  btnName?: string;
  btnAction?: any;
}) {
  // Handling the active link
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [isOpenMobileNavigation, setIsOpenMobileNavigation] =
    useState<boolean>(false);

  useEffect(() => {
    const path = pathname.split("/")[1];
    setActiveLink(path);
  }, [pathname]);

  // Handling the getting the token
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
    }
  }, []);

  return (
    <React.Fragment>
      <nav className="z-50 w-full bg-light shadow-lg h-32">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <div className="flex items-center">
            <Link to={VIEWS.HOME} className="flex items-center">
              <span className="self-center text-2xl font-bold whitespace-nowrap dark:text-white">
                <img src={logo} alt="" className="mx-auto sm:mx-0 h-20" />{" "}
              </span>
            </Link>
            <div className="flex md:order-2 ml-16">
              <div
                className="hidden w-full md:block md:w-auto"
                id="navbar-default"
              >
                <ul className="font-bold flex flex-col p-4 md:p-0 mt-4 border md:flex-row md:space-x-8 md:mt-0 md:border-0 md:mr-8">
                  <li>
                    <Link
                      to={VIEWS.HOME}
                      className={`block py-2 pl-2 pr-4 ${
                        activeLink === ""
                          ? "underline text-black underline-offset-4"
                          : "text-secondary-dark hover:text-secondary-light "
                      }`}
                    >
                      Home <i className="fa-solid fa-house ml-2"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <p className="text-md font-bold">Sales Enquiry</p>
            <p className="text-md font-bold mt-2">
              <i className="fa-solid fa-phone mr-2" />
              1800-102-5558
            </p>
          </div>
          <div
            className="md:hidden"
            id="navbar-sticky"
            onClick={() => setIsOpenMobileNavigation(!isOpenMobileNavigation)}
          >
            <i
              className={`${
                isOpenMobileNavigation
                  ? "fa-solid fa-xmark"
                  : "fa-solid fa-bars"
              } font-bold text-4xl text-purple-600 mr-5`}
            />
          </div>
        </div>
        {isOpenMobileNavigation && (
          <div className="md:hidden w-full h-screen bg-purple-600 mt-20 absolute top-0 overflow-hidden">
            <ul className="flex flex-col justify-start items-center w-full h-full mt-20">
              <li>
                <Link
                  to={VIEWS.HOME}
                  className={`block py-8 pl-2 pr-4 text-4xl font-semibold ${
                    activeLink === ""
                      ? "underline text-white underline-offset-4"
                      : "text-white hover:text-slate-100"
                  }`}
                >
                  Home
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </React.Fragment>
  );
}
