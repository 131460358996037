import { Navigate, Outlet } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import { decodeJwt } from "../common/utils/decode-jwt";
import { UserRoles } from "../common/enums/user-roles.enum";

const UserProtectedRoutes = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { role } = decodeJwt(accessToken);

  if (!accessToken) {
    return <Navigate to={VIEWS.HOME} />;
  }

  if (role === UserRoles.SUPER_ADMIN) {
    return <Navigate to={VIEWS.ADMIN_DASHBOARD} />;
  }

  if (role === UserRoles.COMPANY_ADMIN || role === UserRoles.USER) {
    return <Outlet />;
  }

  return <Navigate to={VIEWS.HOME} />;
};

export default UserProtectedRoutes;
