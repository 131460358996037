import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { ENDPOINT } from "./api-endpoints.routes";

// Create Company API call
export const createCompany = async (token: any, formData: FormData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // If you sending only the JSON data use this format
    },
    timeout: 5000,
  };
  try {
    const response = await axios.post(
      `${ENDPOINT.COMPANY.ROOT}`,
      formData,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
};

// Get all Companies by lesson API call
export const getAllCompanies = async (
  token: any,
  currentPage: number,
  pageSize: number,
  searchQuery: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${ENDPOINT.COMPANY.ROOT_WITH_PAGINATOR(
        currentPage,
        pageSize,
        searchQuery
      )}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};

// Delete Company by ID API call
export const deleteCompany = async (token: any, companyId: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.delete(
      `${ENDPOINT.COMPANY.ROOT_WITH_ID(companyId)}`,
      config
    );
    return { success: true, data: response.data };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message,
    };
  }
};
