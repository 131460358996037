import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import Button from "../button.component";
import useToken from "../../hooks/useToken";
import { useAuth } from "../../common/providers/user.provider";
import { uploadResumeWithCsv } from "../../services/resumes-api.service";
import LoadingSpinner from "../loading-spinner.component";

export default function UploadCsv() {
  useScrollToTop();
  const [searchParams] = useSearchParams();
  const lessonId: string = searchParams.get("id")?.toString() || "";
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "");
  }, []);

  // =====================================================================================================================
  // CREATE ITEM FUNCTIONALITY
  // =====================================================================================================================
  const [formData, setFormData] = useState({
    file: null as File | null,
  });

  // Handling the text input
  const handleInputChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // FILE UPLOADING FUNCTIONALITIES GOES HERE ===============================================================
  interface FileWithPreview extends File {
    preview: string;
  }

  // To select multiple files
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [rejected, setRejected] = useState<File[]>([]);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(false);

  // Define constants for file size limits
  const FILE_LIMIT = 30 * 1024 * 1024; // 30 MB in bytes

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      // Check if the dropzone is disabled (file already selected)
      if (dropzoneDisabled) {
        return;
      }

      // Ensure only one file is accepted
      if (acceptedFiles?.length === 1) {
        const file = acceptedFiles[0];

        // Checking the file size limitations
        const fileSize = file.size;
        const maxFileSize = FILE_LIMIT;
        if (fileSize > maxFileSize) {
          toast.error(
            `File size exceeds the limit. Maximum allowed file size is ${
              maxFileSize / (1024 * 1024)
            } MB.`
          );
          return;
        }

        // Set image file preview url
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        // Read the file content as binary
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryData = e.target?.result;

          // Convert ArrayBuffer to Blob
          const blob = new Blob([binaryData as ArrayBuffer], {
            type: file.type,
          });

          // Update state with the file
          setFormData({
            ...formData,
            file: file,
          });

          // Append the Blob to FormData
          const formDataToSend = new FormData();
          formDataToSend.append("file", file);

          // Use formDataToSend in your API call
          console.log("formDataToSend:", formDataToSend);

          // Disable the dropzone after selecting a file
          setDropzoneDisabled(true);
        };

        // Read file content as binary
        reader.readAsArrayBuffer(file);
      }

      // Handle other rejected files
      if (fileRejections?.length) {
        setRejected((previousFiles) => [
          ...previousFiles,
          ...fileRejections.map((rejection) => rejection.file),
        ]);
      }
    },
    [dropzoneDisabled, formData, setRejected]
  );

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
    // Enable the dropzone after removing the file
    setDropzoneDisabled(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleFormAction = async (e: any) => {
    e.preventDefault();
    const { file } = formData;
    if (!files.length) {
      toast.error("Please select material files");
    } else {
      try {
        setIsLoading(true);

        // Create FormData object to handle file upload
        const formDataToSend = new FormData();

        files.forEach((file) => formDataToSend.append("file", file));
        // Calling to the API service
        const response = await uploadResumeWithCsv(token, formDataToSend);

        // Handling the response
        if (response.success) {
          setFormData({
            file: null,
          });
          setFiles([]);
          window.scrollTo(0, 0);
          toast.success(response?.data?.message);
          setDropzoneDisabled(false);
        } else {
          toast.error(response.error);
          setDropzoneDisabled(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error during form action:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit mx-auto bg-slate-100 rounded-xl px-16 pt-12">
        <form className="w-full bg-slate-100 p-5 rounded-xl my-10">
          <div>
            <div
              {...getRootProps({
                className: "custom-class",
              })}
            >
              <input {...getInputProps()} accept="application/pdf" />
              <div className="flex flex-col items-center justify-center gap-4">
                {isDragActive ? (
                  <div className="flex flex-col items-center justify-center py-5 h-64 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-blue-600 border-dashed">
                    <i className="fa-solid fa-cloud-arrow-up text-blue-700 text-6xl mt-5" />
                    <p className="text-slate-400 text-sm mt-1">
                      Drop the file here ...
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center py-5 h-64 text-center bg-white w-full rounded-xl cursor-pointer border-2 border-gray-300 border-dashed">
                    <i className="fa-solid fa-cloud-arrow-up text-slate-200 text-6xl mt-5" />
                    <p className="text-slate-400 text-sm mt-1">
                      Drag and drop or click to upload file
                    </p>
                  </div>
                )}
              </div>
            </div>
            <section className="mt-2 mb-10">
              {files.map((file: any, index: any) => (
                <div
                  key={index.toString()}
                  className="w-full px-5 py-3 rounded-lg shadow-xm bg-slate-50 text-slate-700 flex flex-row items-center justify-between my-2"
                >
                  <div className="w-full flex flex-row items-center justify-start">
                    <i className="fa-solid fa-file-excel text-2xl text-blue-700 mx-3" />
                    <p className="w-full truncate text-slate-500">
                      {file.name}
                    </p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-end">
                    <button type="button" onClick={() => removeFile(file.name)}>
                      <i className="fa-solid fa-xmark text-xl text-slate-400" />
                    </button>
                  </div>
                </div>
              ))}
            </section>
          </div>
          <div className="flex flex-row justify-end items-center w-full">
            <div className="w-1/5 px-3 mb-6">
              <Button name={"Upload"} handleAction={handleFormAction} />
            </div>
          </div>
        </form>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
