import {
  BrowserRouter,
  Navigate,
  Route,
  RouteProps,
  Routes,
  useNavigate,
} from "react-router-dom";
import { VIEWS } from "./app/routes/routes";
import UserProtectedRoutes from "./app/middleware/user-protected-routes.middleware";
import DashboardLayout from "./app/pages/protected/dashboard-layout";
import Dashboard from "./app/pages/protected/dashboard";
import Settings from "./app/pages/protected/settings";
import Home from "./app/pages/public/home";
import NotFound from "./app/components/not-found.component";
import ActivateAccount from "./app/pages/auth/activate-account";
import ResetPassword from "./app/pages/auth/reset-password";
import Search from "./app/pages/protected/search";
import SearchResults from "./app/pages/protected/search-results";
import Upload from "./app/pages/protected/upload";
import AdminProtectedRoutes from "./app/middleware/admin-protected-routes.middleware";
import AdminDashboard from "./app/pages/protected/admin-dashbaord";
import AdminDashboardLayout from "./app/pages/protected/admin-dashboard-layout";
import AdminCompanies from "./app/pages/protected/admin-companies";
import AdminUsers from "./app/pages/protected/admin-users";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          <Route path={VIEWS.HOME} index element={<Home />} />
          <Route path={VIEWS.HOME_WITH_PREFIX} index element={<Home />} />
          <Route
            path={VIEWS.ACTIVATE_ACCOUNT}
            index
            element={<ActivateAccount />}
          />
          <Route path={VIEWS.RESET_PASSWORD} element={<ResetPassword />} />

          {/* User protected routes */}
          <Route
            path={VIEWS.USER_DASHBOARD_LAYOUT}
            element={<UserProtectedRoutes />}
          >
            <Route
              path={VIEWS.USER_DASHBOARD_LAYOUT}
              element={<DashboardLayout />}
            >
              {/* Redirect to dashboard */}
              <Route index element={<Navigate to={VIEWS.USER_DASHBOARD} />} />
              <Route path={VIEWS.USER_DASHBOARD} element={<Dashboard />} />
              <Route path={VIEWS.USER_SEARCH} element={<Search />} />
              <Route path={VIEWS.USER_UPLOAD} element={<Upload />} />
              <Route path={VIEWS.USER_RESULTS} element={<SearchResults />} />
              <Route path={VIEWS.USER_SETTINGS} element={<Settings />} />
            </Route>
          </Route>

          {/* Admin protected routes */}
          <Route path={VIEWS.ADMIN_DASHBOARD_LAYOUT} element={<AdminProtectedRoutes />}>
            <Route
              path={VIEWS.ADMIN_DASHBOARD_LAYOUT}
              element={<AdminDashboardLayout />}
            >
              <Route index element={<Navigate to={VIEWS.ADMIN_DASHBOARD} />} />
              <Route
                path={VIEWS.ADMIN_DASHBOARD}
                element={<AdminDashboard />}
              />
              <Route
                path={VIEWS.ADMIN_COMPANIES}
                element={<AdminCompanies />}
              />
              <Route path={VIEWS.ADMIN_USERS} element={<AdminUsers />} />
              <Route path={VIEWS.ADMIN_SETTINGS} element={<Settings />} />
            </Route>
          </Route>

          {/* Catch-all route */}
          <Route path="*" Component={NotFound} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
