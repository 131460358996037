import React, { useState } from "react";
import InputField from "../../input-field.component";
import Button from "../../button.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../loading-spinner.component";
import useToken from "../../../hooks/useToken";
import { deleteUser } from "../../../services/user-api.service";
import { useAuth } from "../../../common/providers/user.provider";
import { useNavigate } from "react-router-dom";
import { VIEWS } from "../../../routes/routes";
import PopupConfirmationComponent from "../../popup-confirmation.component";

export default function DeleteAccountForm(props: {}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { token, setToken, clearToken } = useToken();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const handleDeleteAccount = async () => {
    if (!checkEmail.test(email)) {
      toast.error("Please enter valid email");
    } else if (email !== user?.email) {
      toast.error("Please enter your account email correctly");
    } else {
      try {
        setConfirmationOpen(false);
        setIsLoading(true);
        const response = await deleteUser(token, user?.id || "");
        if (response.success) {
          setIsLoading(false);
          clearToken();
          navigate(VIEWS.HOME);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleConfirmDeleteAccount = async () => {
    if (!checkEmail.test(email)) {
      toast.error("Please enter valid email");
    } else if (email !== user?.email) {
      toast.error("Please enter your account email correctly");
    } else {
      setConfirmationOpen(true);
    }
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit bg-slate-100 p-5 lg:p-10 rounded-xl my-10">
        <h1 className="text-xl font-semibold mt-5 mb-10 text-slate-600">
          Delete Your Account
        </h1>
        <p className="text-sm text-slate-500 font-semibold mb-10">
          Delete your account including all uploaded files and submissions. Are
          you sure want to delete the account?
        </p>
        <span className="w-full mt-5">
          <InputField
            icon="fa-solid fa-envelope"
            type="email"
            label="Your account email *"
            placeholder="Enter your account email"
            value={email}
            onChange={setEmail}
          />
        </span>
        <div className="w-full md:w-1/2 lg:w-2/5 mt-5">
          <Button
            name={"Confirm Delete Account"}
            handleAction={handleConfirmDeleteAccount}
          />
        </div>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
      <PopupConfirmationComponent
        popUpDisplay={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleOpen={() => setConfirmationOpen(true)}
        popUpTitle={"Last warning"}
        popUpContent={"Are you sure want to delete account?"}
        handleConfirm={() => handleDeleteAccount()}
      />
    </React.Fragment>
  );
}
